import React, { useContext } from "react"
import { Helmet } from "react-helmet"

import {
  Header,
  NoAuth,
  Loader,
  ProgressContent,
  NoAccess,
} from "../components/index"

// Context
import UserContext from "../context/UserAuth"

const Progress = () => {
  const { isAuthenticated, isLoading, isAccess } = useContext(UserContext) || {
    isAuthenticated: false,
    isLoading: false,
    isAccess: false,
  }

  return (
    <React.Fragment>
      <Helmet>
        <title>Esteekey - Progress</title>
        <meta name="description" content="Esteekey - #1 Fitness app for 2022" />
        <meta name="theme-color" content="#ffffff" data-react-helmet="true" />
      </Helmet>
      <Header />
      <div className="min-h-[calc(100vh-158px)] pt-[69px] xl:pt-0">
        {isLoading && <Loader />}
        {!isAuthenticated && <NoAuth />}
        {!isAccess && <NoAccess />}
        {isAuthenticated && isAccess && <ProgressContent />}
      </div>
    </React.Fragment>
  )
}

export default Progress
